import React, { useState } from "react";
import { useJsApiLoader} from "@react-google-maps/api";
import PlacesAutocomplete from "react-places-autocomplete";
import styled from "styled-components";


const StyledInput = styled.input`
  margin: 0 auto;
  display: block;
  font-size: 18px;
  margin-top: 10px;
  padding: 10px;
  width: 400px;
  max-width: 100%;
  background: #f2f2f2;
  border-radius: 8px;
`;

export const ButtonCalculDistance = styled.button`
  background-color: #e28215;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius:30px;
  margin-top : 1rem;
  margin-bottom:1rem;

  max-height: ${(props) => (props.showForm ? "1000px" : "200px")};

  overflow: hidden;
`;

const center = {
  lat: 50.597857266486976,
  lng: 3.611771298303489,
  address: "Rue du Pont de la Cure 16 C, 7900 Leuze-en-Hainaut",
  name: "LeuzArena",
};

const searchOptions = {
  componentRestrictions: { country: "be" },
};

const libraries = ["places"];

function MapContainer({ onDistanceUpdate }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAMtOwYebOtN9ACRJvQuJkRm3Ua3OtRGGM",
    libraries: libraries,
  });
     

  const [origin, setOrigin] = useState("");
  const [distance, setDistance] = useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const [isEmpty, setIsEmpty] = useState(false);
  const [apiError, setApiError] = useState(false);


  const handleDistanceUpdate = (newDistance) => {
    const roundedDistance = newDistance / 1000;
    setDistance(roundedDistance);
    onDistanceUpdate(roundedDistance);
  
    if (roundedDistance > 20) {
      setWarningMessage("La distance dépasse 20 km.");
      setIsEmpty(!isEmpty);
      setDistance(null);
    } else {
      setWarningMessage("");
    }
  };

  const handleSelect = async (address) => {
    if (address === "") {
      setIsEmpty(true);
      setWarningMessage("Veuillez entrer une adresse.");
    } else {
      setOrigin(address);
      setDistance(null);
      setIsEmpty(false);
      setWarningMessage(null);
      if (document.activeElement instanceof HTMLElement) {
        document.activeElement.blur();
      }
    }
};



const calculateDistance = () => {



  if (!origin) {
    setIsEmpty(true);
    setWarningMessage("Veuillez entrer une adresse.");
    setDistance(null);
  } else {
    setIsEmpty(false);
    setWarningMessage("");
    const service = new window.google.maps.DistanceMatrixService();
    service.getDistanceMatrix(
      {
        origins: [origin],
        destinations: [center.address],
        travelMode: "DRIVING",
        
      },
      (response, status) => {
        if (status === "OK") {
          if (response.rows && response.rows.length > 0 && response.rows[0].elements && response.rows[0].elements.length > 0 && response.rows[0].elements[0].distance) {
            const distance = response.rows[0].elements[0].distance.value;
            handleDistanceUpdate(distance);
          } else {
            setWarningMessage(
              "Il y a eu un problème lors du calcul de la distance entre votre adresse et LeuzArena, veuillez réessayer."
            );
            setDistance(null);
            setApiError(true); // Met à jour la variable d'état apiError
          }
        } else {
          console.error(`La distance n'a pas pu être calculée: ${status}`);
          setWarningMessage(
            "Il y a eu un problème lors du calcul de la distance entre votre adresse et LeuzArena, veuillez réessayer."
          );
          setDistance(null);
          setApiError(true); // Met à jour la variable d'état apiError
        }
      }
    );
  }
};

return isLoaded ? (
  <>
    <PlacesAutocomplete
      value={origin}
      onChange={setOrigin}
      onSelect={handleSelect}
      searchOptions={searchOptions}
      suppressErrors={true} // désactive l'affichage des erreurs
      
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <StyledInput
            {...getInputProps({
              placeholder: "Entrez votre adresse",
              
            })}
          />
          <div>
            {loading ? <div>Chargement...</div> : null}
            {suggestions.map((suggestion, index) => {
              const style = {
                marginTop: "5px",
                backgroundColor: suggestion.active ? "#41b6e6" : "#c41d1d",
                border: "solid 1px black",
              };
              return (
                <div
                  key={index}
                  {...getSuggestionItemProps(suggestion, { style })}
                >
                  {suggestion.description}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </PlacesAutocomplete>
    <ButtonCalculDistance onClick={calculateDistance}>
      Calculer la distance
    </ButtonCalculDistance>
   
    {warningMessage !== "" && <div style={{ color: "red" }}>{warningMessage}</div>}

  </>
) : (
  <></>
);
}

  
  export default React.memo(MapContainer);
  
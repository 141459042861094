import React, { useState } from "react";
import Slider from "react-slick";
import {  FaStar } from "react-icons/fa";

import styled from "styled-components";
import jsonTestimonialsData from "../../data/testimonials.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const TestimonialsWrapper = styled.div`
  width: 100%;
  height: auto;
  text-align: center;
  background-color: ${({theme}) => theme.colors.backgroundColorTestimonials};
`;

export const TestimonialsContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
  max-height:300px;
  

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    
  }
`;

const TestimonialCard = styled.div`
  margin: 0 auto;
  max-width: 500px;

  border-radius: 10px;
  padding: 30px;
  color : ${({theme}) => theme.colors.textColorTestimonials};
`;

const TestimonialText = styled.div`
  font-size: 16px;
  line-height: 1.5;
  min-height: 130px;
  text-align: center;
  justify-content: center;
  align-items: center;
  display:flex;

  font-style: italic;
  font-family: 'Georgia';
 

`;
const TestimonialAuthor = styled.p`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
 
`;

const TestimonialRating = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TestimonialStars = styled.div`
  color: ${({ theme }) => theme.colors.testimonialsStar};
  margin-right: 8px;
`;

const TestimonialsSection = (props) => {
  const { theme } = props;
  const [activeTestimonial, setActiveTestimonial] = useState(0);





  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: activeTestimonial,
   
    autoplay: true,
    autoplaySpeed: 7000,
    afterChange: (id) => setActiveTestimonial(id),
  };

  return (
    <TestimonialsWrapper>
      <Slider {...settings} >
        {jsonTestimonialsData.testimonials.map((testimonial) => (
          <TestimonialCard key={testimonial.id}>
            {" "}
            <TestimonialText>

            &#171; {testimonial.text} &#187;
 
</TestimonialText>
            <TestimonialAuthor>- {testimonial.name}</TestimonialAuthor>
            <TestimonialRating>
              {[...Array(parseInt(testimonial.rating))].map((_, i) => (
                <TestimonialStars key={i}>
                  <FaStar size={22} />
                </TestimonialStars>
              ))}
            </TestimonialRating>
          </TestimonialCard>
        ))}
      </Slider>
      </TestimonialsWrapper>
  );
};

export default TestimonialsSection;

import styled from "styled-components";

export const ContactSectionStyled = styled.section`
  min-height: 102vh;
  width: 100%;

  padding: 8rem 10rem 5rem 10rem;
  background-color: ${({ theme }) => theme.colors.contactSectionBackground};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 7rem 0.2rem 5rem 0.2rem;
    text-align: center;
  }
`;

export const ContactContainerStyled = styled.div`
  width: 100%;
`;

export const ContactFormAndTextContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }
`;

export const ContactTextStyled = styled.div`
  width: 50%;
  padding: 5rem;
  font-size: 1.4rem;
  text-align: justify;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    padding: 1rem;
    font-size: 1.2rem;
  }

  p {
    padding: 1rem;
  }
`;

export const ContactFormStyled = styled.div`
  width: 50%;
  //margin-bottom:8rem;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 92%;
  }
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;

    input {
      width: 100%;
      height: 35px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      border: 1px solid rgb(220, 220, 220);

      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
      &:hover {
        color: ${({ theme }) => theme.colors.aLinkHover};
      }
    }
    textarea {
      max-width: 100%;
      min-width: 100%;
      width: 100%;
      max-height: 100px;
      min-height: 100px;
      padding: 7px;
      outline: none;
      border-radius: 5px;
      margin-bottom: 3rem;
      border: 1px solid rgb(220, 220, 220);
      &:focus {
        border: 2px solid rgba(0, 206, 158, 1);
      }
    }
    label {
      margin-top: 1rem;
      margin-bottom: 1rem;
      margin-left: 0.3rem;
    }
    input[type="submit"] {
      margin-top: 2rem;
      cursor: pointer;
      background: ${({ theme }) => theme.colors.backgroundAndBorderButton};
      width: 100%;

      color: ${({ theme }) => theme.colors.textButtonOne};

      font-size: 18px;
      font-family: "Montserrat", sans-serif;

      border-radius: 10px;

      &:hover {
        background: ${ ({ theme}) =>  theme.colors.aHoverBackgroundAndBorderButton  };
    color: ${ ({ theme}) =>  theme.colors.textButtonOne };

  }

    }
  }
`;

export const CTAButtonSubmitStyled = styled.a`
  margin-top: 2rem;
  cursor: pointer;

  width: 100%;

  background: ${({ primary, theme }) =>
    primary ? theme.colors.backgroundAndBorderButton : "none"};
  color: ${({ primary, theme }) =>
    primary ? theme.colors.textButtonOne : theme.colors.textButtonOne};

  border: 3px solid ${({ theme }) => theme.colors.backgroundAndBorderButton};
  font-size: 26px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;

  border-radius: 20px;

  &:hover {
    background: ${({ primary, theme }) =>
      primary
        ? theme.colors.aHoverBackgroundAndBorderButton
        : theme.colors.aHoverBackgroundAndBorderButton};
    color: ${({ theme }) => theme.colors.aLinkHover};
    border: 3px solid
      ${({ theme }) => theme.colors.aHoverBackgroundAndBorderButton};
  }
`;

export const SEOParagrapheStyled = styled.div`
display: none;
`
import styled from "styled-components";
export const PolitiqueDeConfidentialiteSectionStyled = styled.section`
  min-height: 100vh;
  width: 80%;
  padding: 2rem 0;
 justify-content: center;
 background-color: ${({ theme }) =>
    theme.colors.politiqueDeConfidentialiteSectionBackground};
  text-align: center;
margin : 0 auto;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 6rem 1rem 5rem 1rem;
    text-align: center;
    width:100%;
  }
`;

export const PolitiqueDeConfidentialiteContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 2rem;
  background-color: ${({ theme }) =>
    theme.colors.politiqueDeConfidentialiteSectionBackground};

  h1 {
    font-size: 3rem;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.mentionsLegalesh1};
  }

  h2 {
    font-size: 2.1rem;
    margin: 7rem 0 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesh2};
  }

  h3 {
    font-size: 1.7rem;
    margin-top: 2.5rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesh3};
  }

  p {
    font-size: 1.4rem;
    margin: 1.2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesParagraphe};
    justify-content: center;
    margin-bottom:4rem;
    padding-left:4rem;
    padding-right:4rem;

  }
  a{
    color: ${({ theme }) => theme.colors.mentionsLegalesParagraphe};
  }
  a:hover {
    color:${({ theme }) => theme.colors.mentionsLegalesHover};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0;

    h2 {
      margin-top: 6rem;
    }

    p {
      text-align:justify;
      padding: 0.5rem;
    }
  }
`;

export const PolitiqueDeConfidentialiteImageContainerStyled = styled.div`
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  display: flex;
  margin-bottom: 3rem;
`;

import {
  AboutContainerStyled,
  AboutContentStyled,
  AboutSectionStyled,
  ImageStyled,
  ImageContainerStyled,
  AboutContainerImageAndTextStyled,
  AboutContainerTextStyled,

} from "../styles/sections/AboutSection.styled";
import { SectionHeadingStyled } from "../styles/common/SectionHeading.style";

import ImageAnimAlice from "../../assets/images/section/about-section/AnimAlice.jpg";

import WaveSeparatorAboutAndServices from "../layout/WaveSeparatorAboutAndServices";
import { WaveSeparatorAboutAndServicesContainer } from "../layout/WaveSeparatorAboutAndServices";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import  { CustomHRWithIcon } from "../styles/common/HrBar.styled";
import { FaUser } from "react-icons/fa";


export const AboutSection = ({ theme, darkMode }) => {
  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 20,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
  }, []);

  return (
    <>
      <WaveSeparatorAboutAndServicesContainer>
        <AboutSectionStyled id="about">
          <AboutContainerStyled>
            <AboutContentStyled>
              <SectionHeadingStyled data-aos="fade-right">
                <h2>A propos</h2>
              
              </SectionHeadingStyled>
              <CustomHRWithIcon icon={FaUser}  data-aos-delay="200"/>
              <AboutContainerImageAndTextStyled data-aos="fade-up" data-aos-delay="200">
                <ImageContainerStyled data-aos="fade-up">
                  <ImageStyled src={ImageAnimAlice} alt="AnimAlice" />
                </ImageContainerStyled>
                <AboutContainerTextStyled data-aos="fade-up" >
                  <h3 >Bonjour !</h3> 
                 
                  <br /> <br />
                  <p >
                    Moi c’est <strong>Alice</strong>. J’ai fait toute mon
                    enfance à <strong>Leuze-en-Hainaut</strong> et je suis propriétaire d’une
                    maison dans le quartier <strong>Bon-Air</strong>.
                  </p>
                  <p >
                    J’ai toujours adoré les <strong>animaux</strong> et j’ai
                    donc dirigé mon <strong>cursus</strong> vers un{" "}
                    <strong>métier</strong> qui pourrait les aider. En 2018,
                    l’école IEPS de Dour m’octroie l’
                    <strong>attestation</strong> d’
                    <strong>Assistante Vétérinaire</strong> avec{" "}
                    <strong>grande distinction</strong> et je suis tout de suite
                    employée sur mon <strong>lieu de stage</strong> chez le{" "}
                    <strong>Dr. Vantyghem</strong> à <strong>Bassilly</strong>{" "}
                    où je travaille toujours en <strong>temps partiel</strong>{" "}
                    aujourd’hui (4/5).
                  </p>
                  <p >
                    C’est à mon travail que j’ai pu acquérir une certaine{" "}
                    <strong>expérience</strong> dans le domaine et que j’ai pu
                    me rendre compte qu’il n’est pas souvent évident pour les{" "}
                    <strong>propriétaires</strong> d’appliquer eux même les{" "}
                    <strong>conseils</strong> que nous donnons, à la maison.
                  </p>
                  <p >
                    Même si l’idée me trottait dans la tête depuis un moment, le
                    covid ne permettait pas de pouvoir me lancer comme{" "}
                    <strong>indépendante complémentaire</strong> sereinement.
                    Mais ce n’est plus le cas car depuis le 1er février 2022, je
                    me rends chez vous pour vous <strong>aider</strong> avec vos{" "}
                    <strong>animaux chéris</strong> !
                  </p>
                  <p  >
                    Au plaisir de vous <strong>rencontrer</strong> ! 😊
                  </p>
                </AboutContainerTextStyled>
              </AboutContainerImageAndTextStyled>
            </AboutContentStyled>
            
          </AboutContainerStyled>
          
        </AboutSectionStyled>
        
        
        <div>
          <WaveSeparatorAboutAndServices theme={theme} darkMode={darkMode} />
        </div>
      </WaveSeparatorAboutAndServicesContainer>
    </>
  );
};

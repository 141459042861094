import styled from "styled-components";

export const CTAButtonStyled = styled.a`
    background: ${ ({primary, theme}) => primary ? theme.colors.backgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    padding: 15px 30px;
    margin: ${ ({margin}) => margin};
    border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 22px;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;

    &:hover{
        background: ${ ({primary, theme}) => primary ? theme.colors.aHoverBackgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
    }

    @media (max-width: 350px) {
    padding:12px 20px;
    font-size: 18px;
  }
`
import styled, { keyframes } from "styled-components";



const fadein = keyframes`
  from {
    opacity: 0;
   
  }
  to {
    opacity: 1;
    
  }
`;

const slideLeft = keyframes`
  from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-50px);
    }
  
`;

const slideUp = keyframes`
  from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-50px);
    }
  
`;
/*
const slideDown = keyframes`
  from {
      transform: translateY(0);
    }
    to {
      transform: translateY(50px);
    }
  
`;
*/
const slideRight = keyframes`
  from {
      transform: translateX(0);
    }
    to {
      transform: translateX(50px);
    }
  
`;

const bounce = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const HeroSectionStyled = styled.section`
  width: 100%;
  min-height: 100vh;
  
 
  background: linear-gradient(
    135deg,
    ${({ theme }) => theme.colors.heroSectionHeaderLinearOne},
    ${({ theme }) => theme.colors.heroSectionHeaderLinearTwo}
  );
 
 max-width:100%;
  background-size: contain;
object-fit: cover;

 
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 3;
 
  

  animation: ${fadein} 1s ease-in-out forwards;
   

  @media (max-width: ${({ theme }) => theme.mobile}) {
    background: linear-gradient(
        135deg,
        ${({ theme }) => theme.colors.heroSectionHeaderLinearOneSvg},
        ${({ theme }) => theme.colors.heroSectionHeaderLinearTwoSvg}
      );
    background-position: center;
    margin: auto;
  
   
   
   
  }
`;

export const HeroContentStyled = styled.div`
  width: 100%;
 
 
  
  transition: ${({ theme }) => theme.transition};

  display: flex;
  align-items: center;
  justify-content: center;


  

 

  p {
    font-size: 1.7rem;
    font-weight: 400;
    text-transform: none;
  }

  div {
    margin: 2.5rem 0;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    
    
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 2rem;
 
    margin: auto;
    max-width: 400px;
   
   
   
    
    
  }
`;



export const ImageAnimaliceStyled = styled.img`

width: 55rem;
height: 55rem;
max-width:100%;
margin-left:20rem;

animation: ${fadein} 0.5s ease-in-out forwards, ${slideRight} 1s ease-in-out forwards;

@media (max-width: ${({ theme }) => theme.mobile}) {
  width: 30vh; 
  height:auto;
  max-width:100%;
  margin-left:0;
  padding:0;

  
  animation: ${fadein} 0.5s ease-in-out forwards, ${slideUp} 1s ease-in-out forwards;
  
  margin-top:11rem;
}
`;


export const TextContainerStyled = styled.div`
  padding-right:30rem;
  
    
  align-items: center;
  animation: ${fadein} 0.5s ease-in-out forwards, ${slideLeft} 1s ease-in-out forwards;
  
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    animation: ${fadein} 0.5s ease-in-out forwards, ${slideUp} 1s ease-in-out forwards;
    padding-right:0;
    
  }
`;

export const TitleStyled = styled.h1`


  max-width: 100%;
  overflow: hidden;
  font-family: 'Finger Paint', cursive;
      

     
  
      width: 18ch;
    letter-spacing: 1.2rem;
    font-size: 6.2rem;
    font-weight: 600;
    overflow: hidden;
    white-space: nowrap;

    margin: auto;

    color: ${({ theme }) => theme.colors.titleAnimAlice};

    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 2.8rem;
    letter-spacing: 0.5rem;
   


    }

 
  
`;

export const SubtitleStyled = styled.h3`
  font-size: 2.2rem;
  margin-top:1rem;
  color: ${({ theme }) => theme.colors.titleTwoAnimAlice};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.4rem;
    margin-top: 0.3rem;
  }
`;

export const SubtitleTwoStyled = styled.h3`

  color: gray;
 
    font-size: 1.3rem;
    margin: 1.5rem 0;
    background: linear-gradient(
      to right,
      ${({ theme }) => theme.colors.titleThreeLinearGradientOne} 0%,
      ${({ theme }) => theme.colors.titleThreeLinearGradientTwo} 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 1.2rem;
    margin-top: 1rem;
   width:100%;
  }
`;

export const ArrowDownStyled = styled.a`
  position: absolute;
  bottom: 50px;
  svg {
    font-size: 70px;
    color: ${({ theme }) => theme.colors.arrowDown};
    animation: ${bounce} 2s infinite 2s;
   
    &:hover {
      color: ${({ theme }) => theme.colors.arrowDownHover};
      transform: ${({ theme }) => theme.transform};
      
    }
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display:none;
    bottom: 30px;
  }

`;




export const ImageSvgLapinStyled = styled.img`
  position: absolute;
  bottom: 2%;
  left: 85%;
  width: 220px;
  height: auto;
  opacity:0.3;
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    left:70%;
  }
`;

export const ImageSvgChatStyled = styled.img`
  position: absolute;
  bottom: 38%;
  left: 93%;
  width: 220px;
  height: auto;
  opacity:0.3;
  transform: rotate(-60deg);
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display:none;
  }
`;




export const CTAButtonAProposStyled = styled.a`
    background: ${ ({primary, theme}) => primary ? theme.colors.backgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    padding: 10px 20px;
    margin: ${ ({margin}) => margin};
    border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 23px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;

    &:hover{
      background: ${ ({theme}) => theme.colors.textButtonTwo };
    color: ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton };
    border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
    }

`

export const CTAButtonServicesStyled = styled.a`
    background: ${ ({primary, theme}) => primary ? theme.colors.backgroundAndBorderButton : "none" };
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonTwo : theme.colors.textButtonTwo };
    padding: 10px 20px;
    margin: ${ ({margin}) => margin};
    border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 23px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;

    &:hover{
      background: ${ ({theme}) => theme.colors.hoverButtonBackground};
    color:  ${ ({theme}) => theme.colors.hoverButtonText}; 
    border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
      background: none;
      color: white;
      margin-right:20px;
     
    }

`

export const CTAButtonContactStyled = styled.a`
    background: ${ ({primary, theme}) => primary ?   "none" : theme.colors.backgroundAndBorderButton};
    color: ${ ({primary, theme}) => primary ? theme.colors.textButtonOne : theme.colors.textButtonOne };
    padding: 10px 20px;
    margin: ${ ({margin}) => margin};
    border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 23px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    cursor: pointer;
    display: inline-block;
    border-radius: 20px;
    

    &:hover{
      background: ${ ({theme}) => theme.colors.textButtonTwo };
    color: ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton };
    border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
    }

    @media (max-width: ${({ theme }) => theme.mobile}) {
      background: ${ ({theme}) =>   theme.colors.backgroundAndBorderButton };
      
      margin: 0 auto;
      margin-top:1rem;
      
    }

`

import { useState, useEffect } from "react";
import { NavItems } from "../../data/NavItems";
import {
  LineOneStyled,
  LineThreeStyled,
  LineTwoStyled,
  MenuIconStyled,
  NavStyled,
  NavListStyled,
  NavItemStyled,
  NavLinkStyled,
  SocialIconsStyled,
  LogoAnimAliceStyled,
  ProfileStyled,
  ThemeContainer
} from "../styles/layout/Nav.styled";

import { FaFacebook } from "react-icons/fa";

import AnimAliceLogo from "../../assets/images/loading/loading.png"

const MOBILE_WIDTH_THRESHOLD = 1150;

export const NavBar = ({ setDarkMode, darkMode }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < MOBILE_WIDTH_THRESHOLD);
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < MOBILE_WIDTH_THRESHOLD);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <NavStyled>
        <LogoAnimAliceStyled href="#home" >
       
       
         AnimAlice
          
        </LogoAnimAliceStyled>
        
        {isMobile && (
           <ThemeContainer onClick={() => setDarkMode(!darkMode)}>
           {darkMode ? (
             <svg
               className="icon-moon"
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               fill="white"
               viewBox="0 0 24 24"
             >
               <path
                 stroke="#fff"
                 strokeLinecap="round"
                 strokeLinejoin="round"
                 strokeWidth="1.5"
                 d="M2.03 12.42c.36 5.15 4.73 9.34 9.96 9.57 3.69.16 6.99-1.56 8.97-4.27.82-1.11.38-1.85-.99-1.6-.67.12-1.36.17-2.08.14C13 16.06 9 11.97 8.98 7.14c-.01-1.3.26-2.53.75-3.65.54-1.24-.11-1.83-1.36-1.3C4.41 3.86 1.7 7.85 2.03 12.42z"
               ></path>
             </svg>
           ) : (
             <svg
               className="icon-sun"
               xmlns="http://www.w3.org/2000/svg"
               width="30"
               height="30"
               fill="none"
               viewBox="0 0 24 24"
             >
               <g fill="#ffffff" clipPath="url(#clip0_1300_106215)">
                 <path d="M12 0a1 1 0 011 1v3a1 1 0 11-2 0V1a1 1 0 011-1z"></path>
                 <path
                   fillRule="evenodd"
                   d="M7 12a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6z"
                   clipRule="evenodd"
                 ></path>
                 <path d="M20.485 4.929a1 1 0 00-1.414-1.414L16.95 5.636a1 1 0 001.414 1.414l2.121-2.121zM24 12a1 1 0 01-1 1h-3a1 1 0 110-2h3a1 1 0 011 1zM19.071 20.485a1 1 0 001.414-1.414l-2.121-2.121a1 1 0 10-1.414 1.414l2.121 2.121zM12 24a1 1 0 01-1-1v-3a1 1 0 112 0v3a1 1 0 01-1 1zM3.515 19.071a1 1 0 101.414 1.414l2.121-2.121a1 1 0 00-1.414-1.414L3.515 19.07zM0 12a1 1 0 011-1h3a1 1 0 110 2H1a1 1 0 01-1-1zM4.929 3.515a1 1 0 10-1.414 1.414L5.636 7.05A1 1 0 007.05 5.636L4.93 3.515z"></path>
               </g>
               <defs>
                 <clipPath id="clip0_1300_106215">
                   <path fill="#fff" d="M0 0H24V24H0z"></path>
                 </clipPath>
               </defs>
             </svg>
           )}
         </ThemeContainer>
        )}
        <MenuIconStyled menuOpen={menuOpen} onClick={() => setMenuOpen(!menuOpen)}>
          <LineOneStyled menuOpen={menuOpen} />
          <LineTwoStyled menuOpen={menuOpen} />
          <LineThreeStyled menuOpen={menuOpen} />
        </MenuIconStyled>
        <NavListStyled menuOpen={menuOpen}>
          {NavItems &&
            NavItems.map((navItem, index) => (
              <NavItemStyled
                key={index}
                index={index}
                menuOpen={menuOpen}
                onClick={() => setMenuOpen(false)}
              >
                {navItem.isProfile ? (
                  <NavLinkStyled href="#home">
                    <ProfileStyled>
                      <img
                        src={AnimAliceLogo}
                        alt="AnimAlice"
                      ></img>
                    </ProfileStyled>
                    
                  </NavLinkStyled>

                  
                ) : null}
               

                {navItem.text ? (
                  <NavLinkStyled href={navItem.href}>{navItem.text}</NavLinkStyled>
                ) : null}
                {navItem.hasSocialIcons ? (
                  <SocialIconsStyled>
                    <NavLinkStyled href="https://www.facebook.com/AnimAlice.be" target="_blank">
                      <FaFacebook />
                    </NavLinkStyled>
                    
                    
                  </SocialIconsStyled>
                ) : null}
              </NavItemStyled>
            ))}

        {!isMobile && (
          <div onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? (
              <svg
                className="icon-moon"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="white"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="#fff"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M2.03 12.42c.36 5.15 4.73 9.34 9.96 9.57 3.69.16 6.99-1.56 8.97-4.27.82-1.11.38-1.85-.99-1.6-.67.12-1.36.17-2.08.14C13 16.06 9 11.97 8.98 7.14c-.01-1.3.26-2.53.75-3.65.54-1.24-.11-1.83-1.36-1.3C4.41 3.86 1.7 7.85 2.03 12.42z"
                ></path>
              </svg>
            ) : (
              <svg
                className="icon-sun"
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="30"
                fill="none"
                viewBox="0 0 24 24"
              >
                <g fill="#ffffff" clipPath="url(#clip0_1300_106215)">
                  <path d="M12 0a1 1 0 011 1v3a1 1 0 11-2 0V1a1 1 0 011-1z"></path>
                  <path
                    fillRule="evenodd"
                    d="M7 12a5 5 0 1110 0 5 5 0 01-10 0zm5-3a3 3 0 100 6 3 3 0 000-6z"
                    clipRule="evenodd"
                  ></path>
                  <path d="M20.485 4.929a1 1 0 00-1.414-1.414L16.95 5.636a1 1 0 001.414 1.414l2.121-2.121zM24 12a1 1 0 01-1 1h-3a1 1 0 110-2h3a1 1 0 011 1zM19.071 20.485a1 1 0 001.414-1.414l-2.121-2.121a1 1 0 10-1.414 1.414l2.121 2.121zM12 24a1 1 0 01-1-1v-3a1 1 0 112 0v3a1 1 0 01-1 1zM3.515 19.071a1 1 0 101.414 1.414l2.121-2.121a1 1 0 00-1.414-1.414L3.515 19.07zM0 12a1 1 0 011-1h3a1 1 0 110 2H1a1 1 0 01-1-1zM4.929 3.515a1 1 0 10-1.414 1.414L5.636 7.05A1 1 0 007.05 5.636L4.93 3.515z"></path>
                </g>
                <defs>
                  <clipPath id="clip0_1300_106215">
                    <path fill="#fff" d="M0 0H24V24H0z"></path>
                  </clipPath>
                </defs>
              </svg>
            )}
          </div>
        )}
        </NavListStyled>
      </NavStyled>
    </>
  );
};

import React from 'react'


import MentionsLegalesSection from '../sections/MentionsLegalesSection'

export default function MentionsLegalesPage({theme, setDarkMode, darkMode}) {
  return (
   
   
      <MentionsLegalesSection theme={theme}/>
   
  )
}

import styled from "styled-components";

export const Container = styled.div`
  margin-top: 40px;
  text-align: center;
  width:100%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem 0rem 2rem 0;
  }
`;

export const ButtonShowSimulationTarifs = styled.button`
  background-color: ${({theme})=> theme.colors.backgroundAndBorderButton};
  color: ${ ({ theme}) => theme.colors.textButtonOne };
  border: ${({theme}) => theme.colors.textButtonOne};
  padding: 10px 20px;
  border: 3px solid ${ ({theme}) => theme.colors.backgroundAndBorderButton};
    font-size: 23px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    cursor: pointer;

    border-radius: 10px;
  transition: 300ms ease-in-out;
    &:hover{
      background: ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton };
  color: ${ ({theme}) => theme.colors.textButtonOne};
  border: 3px solid ${ ({theme}) => theme.colors.aHoverBackgroundAndBorderButton};
  }

  max-height: ${(props) => (props.showForm ? "1000px" : "200px")};

  overflow: hidden;
`;

export const ButtonShowMap = styled.button`
  background-color: #e28215;
  border: none;
  color: white;
  font-size: 16px;
  padding: 10px;
  cursor: pointer;
  border-radius:30px;

  max-height: ${(props) => (props.showForm ? "1000px" : "200px")};

  overflow: hidden;
`;

export const Form = styled.form`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;

  height: ${(props) => (props.showForm ? "auto" : "0")};

  overflow: hidden;
  margin-bottom: 40px;

  .transition {
  transition: opacity 2s ease-in-out, height 2s ease-in-out;
}


`;

export const Label = styled.label`
  display: block;
  margin-top: 20px;
  font-weight: bold;
  color: ${({theme}) => theme.colors.tarifsSimulatorLabel};
  font-size: 18px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
 
 width:100%;

}
`;

export const Input = styled.input`
  
  display: block;
  font-size: 18px;
  margin-top: 10px;
  padding: 10px;
  width: 400px;
  max-width: 100%;
  border:none;
  margin: 0 auto;
  color: black;
  font-weight:600;
  text-align:center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
 
   width:100%;
 
  }
 
`;

export const Select = styled.select`
  margin: 0 auto;
  display: block;
  font-size: 18px;
  margin-top: 10px;
  padding: 10px;
  width: 400px;
  max-width: 100%;
  color: #000;
  background: #f2f2f2;
  border: none;
  outline: none;
  border-radius: 8px;
  @media (max-width: ${({ theme }) => theme.mobile}) {
 
 width:100%;

}
`;

export const Option = styled.option`
  &:hover {
    background: #2ecc71;
    color: #fff;
  }
  &:nth-child(odd) {
    background: #f9f9f9;
  }
  &:nth-child(even) {
    background: #e5e5e5;
  }
`;

export const ResultTable = styled.table`
  margin-top: 20px;
  
  width: 65%;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width:100%
  }

  &:nth-last-child(2) {
    margin-bottom: 20px;
  }
`;

export const ResultLabel = styled.td`
  color: #8d2d2d;
  font-weight: bold;
  font-size: 24px;
`;

export const ResultValue = styled.td`
  color: orange;
  font-weight: bold;
  font-size: 24px;
`;

export const ResultDescription = styled.td`
  color: #555;
  font-size: 14px;
`;

export const PrestationLabel = styled(ResultLabel)`
  width: 70%;

  background-color: ${({theme}) => theme.colors.tarifsSimulatorPrice};
  font-size: 20px;
  color: ${({theme}) => theme.colors.tarifsSimulatorTextTable};
`;
export const PrestationValue = styled(ResultValue)`
  width: 30%;
  color: ${({theme}) => theme.colors.tarifsSimulatorTextTable};

  background-color: ${({theme}) => theme.colors.tarifsSimulatorPrice};
`;
export const FraisDeplacementLabel = styled(ResultLabel)`

  color: ${({theme}) => theme.colors.tarifsSimulatorTextTable};

  background-color: ${({theme}) => theme.colors.tarifsSimulatorPrice};
  font-size: 20px;
  
`;
export const FraisDeplacementValue = styled(ResultValue)`

  color: ${({theme}) => theme.colors.tarifsSimulatorTextTable};;

  background-color: ${({theme}) => theme.colors.tarifsSimulatorPrice};;
  font-size: 24px;
  
`;

export const TotalLabel = styled(ResultLabel)`
  color: ${({theme}) => theme.colors.tarifsSimulatorTextTotalTable};;
  border-radius: 7px;
  background-color: #b90000;
  font-size: 30px;
  width: 80%;
`;
export const TotalValue = styled(ResultValue)`
  color: ${({theme}) => theme.colors.tarifsSimulatorTextTotalTable};;
 
  background-color: #b90000;
  font-size: 55px;
  border-radius: 7px;
`;

export const CoutJourneeLabel = styled(ResultLabel)`
  font-size: 24px;
  color: black;
  background-color: #e2d1d1;
`;
export const CoutJourneeValue = styled(ResultValue)`
  color: black;
  background-color: #e2d1d1;
  font-size: 24px;
`;

export const SpaceLabel = styled(ResultLabel)`
  font-size: 22px;
`;
export const SpaceValue = styled(ResultValue)`
  color: #ffffff;
`;

export const LabelHeader = styled.th`
  font-size: 22px;

  text-align: center;
  margin: 0 auto;
  justify-content: center;
  background-color: ${({theme}) => theme.colors.tarifsSimulatorTitle};
  color: white;

  width: 100%;
  display: table-cell;

  border-radius: 4px;
  padding: 5px;
  height: 50px;
`;

export const SecondTableHeader = styled.thead`
  margin-top: 20px;
`;

export const TrTotal = styled.tr`
  td {
    background-color: ${({theme}) => theme.colors.tarifsSimulatorTotal};
    border-radius: 4px;
    height: 40px;
    font-size: 26px;
    font-weight: 600;
  }
`;

export const InfoLabel = styled(Label)`
  position: relative;
  cursor: pointer;
  &:hover {
    &::after {
      content: "Informations supplémentaires";
      position: absolute;
      bottom: 100%;
      left: 50%;
      transform: translateX(-50%);
      padding: 10px;
      background-color: #333;
      color: #fff;
      border-radius: 5px;
      font-size: 14px;
    }
  }
`;

export const TarifTotal = styled.tr``;

export const InfoDistanceVoiture = styled.span`
display:block;
font-size:11px;
max-width:500px;
margin:0 auto;
`
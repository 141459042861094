import {
  ImageAnimaliceStyled,
  ArrowDownStyled,
  HeroContentStyled,
  HeroSectionStyled,
  TitleStyled,
  TextContainerStyled,
  SubtitleStyled,
  SubtitleTwoStyled,
  ImageSvgLapinStyled,
  ImageSvgChatStyled,

} from "../styles/sections/HeroSection.styled";

import { FaChevronDown } from "react-icons/fa";
import ImageAlice from "../../assets/images/section/hero-section/Animalice.png";
import { useState, useEffect } from "react";
import ImageLapin from "../../assets/images/section/hero-section/lapin.svg";
import ImageChat from "../../assets/images/section/hero-section/chat.svg"
import { CTAButtonStyled } from "../styles/common/CTAButton.styled";

export const HeroSection = ({ theme, darkMode }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1150);
   
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <HeroSectionStyled id="home" scroll-margin-top="80">
      <HeroContentStyled>
        <ImageAnimaliceStyled src={ImageAlice} alt="AnimAlice"/>
        <ImageSvgLapinStyled src={ImageLapin} alt="Lapin blessé"/>
        <ImageSvgChatStyled src={ImageChat} alt="Chat blessé"/>

        <TextContainerStyled>
          <TitleStyled>AnimAlice</TitleStyled>
          <SubtitleStyled>Aide-soignante vétérinaire à domicile</SubtitleStyled>
          <SubtitleTwoStyled>
            Leuze-en-Hainaut et ses environs
          </SubtitleTwoStyled>
          <div>
            <CTAButtonStyled href="#about">
              A propos
            </CTAButtonStyled>
            <CTAButtonStyled
              href="#services"
              primary = {!isMobile}
              margin="0 0 0 20px"
            >
              Services
            </CTAButtonStyled>
            {isMobile ? (<div><CTAButtonStyled
              href="#contact"
              primary = {isMobile}
              margin={isMobile ? "0px 0 0 0px" : "0px 0 0 0px"}
            >
              Contact
            </CTAButtonStyled></div>) : (<CTAButtonStyled
              href="#contact"
              primary = {isMobile}
              margin="0px 0 0 20px"
            >
              Contact
            </CTAButtonStyled>)}
            
           
          </div>
        </TextContainerStyled>
      </HeroContentStyled>

      <ArrowDownStyled href="#about">
        <FaChevronDown  />
      </ArrowDownStyled>
    </HeroSectionStyled>
  );
};

import styled from "styled-components";

const DividerCustom = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.25rem 0 3rem;
  width: 100%;
 


  & > div:first-child {
    margin-right: 1rem;
  }

  & > div:last-child {
    margin-left: 1rem;
  }
`;

const DividerCustomLine = styled.div`
  width: 100%;
  max-width: 11rem;
  height: 0.25rem;
  border-radius: 1rem;
  border-color: ${({ theme }) => theme.colors.hrDividerColor} ;
  background-color: ${({ theme }) => theme.colors.hrDividerColor} ;

  &:first-child {
    margin-right: 1rem;
  }

  &:last-child {
    margin-left: 1rem;
  }
`;

const DividerCustomIcon = styled.div`
  color: ${({ theme }) => theme.colors.hrIconColor} ;
  font-size: 2.5rem;
`;

export const CustomHRWithIcon = ({ icon: Icon, iconSize }) => {
  const enlargedIconSize = iconSize * 3;

  return (
    <DividerCustom data-aos="flip-right">
      <DividerCustomLine />
      <DividerCustomIcon>
        <Icon size={enlargedIconSize} />
      </DividerCustomIcon>
      <DividerCustomLine />
    </DividerCustom>
  );
};

import styled from "styled-components";

export const GalerieSectionStyled = styled.section`
  min-height: 102vh;
  width: 100%;
  
  
  padding: 7rem 5rem 5rem 5rem;
  background-color: ${({ theme }) => theme.colors.galerieSectionBackground};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 7rem 2rem 5rem 2rem;
    text-align:center;
  }
`;



export const GalerieContentStyled = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  color: ${({ theme }) => theme.colors.aboutSectionTextTitle};

  p {
    font-family: "Poppins", sans-serif;
    font-size: 1.6rem;
    text-align: justify;
    margin-bottom: 1.45rem;
    color: ${({ theme }) => theme.colors.aboutSectionTextContent};
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.3rem;
      margin-bottom: 1.45rem;
    }
  }
`;

import styled from "styled-components";

export const ServicesSectionStyled = styled.section`
  min-height: 102vh;
  width: 100%;
  
  
  padding: 5rem 0 5rem 0;
  background-color: ${({ theme }) => theme.colors.servicesSectionBackground};
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 6rem 0rem 5rem 0rem;
    text-align:center;
  }
`;

export const ServicesContainerStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: 7rem;
  padding-left: 10rem;
  padding-right: 10rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0rem;
    padding-left: 0rem;
    padding-right: 0rem;
  }
`;

export const ServiceBannerStyled = styled.div`
  width: 100%;
  max-width:1200px;
  display: flex;
  flex-wrap: wrap;
  align-items:center;
 
  justify-content: space-between;

 
  border-radius: 10px;
  text-align: center;
  padding: 30px 20px;
  transition: all 0.3s ease-in-out;
  margin-bottom:2rem;
  margin: 0 auto;
 
  @media (max-width: 1150px) {
    flex-direction: column;
    
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
 
    
    justify-content: center;
    align-items:center;
    padding: 0;
    
    
  }
  
 
`;

export const ServiceDescriptionStyled = styled.div`

  font-size: 1.5rem;
  color: #ad4848;
  line-height: 1.5;
  text-align:justify;
  padding:3rem;
  padding-right:6rem;
  padding-left:6rem;
  width:100%;
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
   
    margin:0 auto;
    padding: 0;
    padding-right:0;
    padding-left:0;
    font-size:1.2rem;
  
  }
`;

export const ServiceTitleStyled = styled.h3`
  font-size: 3rem;
  color: ${({theme}) => theme.colors.servicesTextTitle};
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
   
   margin:0 auto;
   padding: 0;
   margin-bottom:3rem;
 }
  
`;

export const ServiceImageContainer = styled.div`
  width: 40%;

  @media (max-width: 1150px) {
    width:45%;
    margin: 0 auto;
    
  }

  @media (max-width: 900px) {
    width:55%;
    margin: 0 auto;
    
  }
  @media (max-width: 700px) {
    width:68%;
    margin: 0 auto;
    
  }
 
  @media (max-width: 500px) {
    width:80%;
    margin: 0 auto;
    
  }
 
`;

export const ServiceTextContainer = styled.div`
  width: 60%;
  

  p {
    margin:2rem;
    color: ${({theme}) => theme.colors.servicesTextParagraph};
  }
  
  @media (max-width: 1150px) {
    width:70%;
    margin: 0 auto;
   
    
  }
  @media (max-width: 900px) {
    width:80%;
    margin: 0 auto;
  
    
  }
  @media (max-width: 700px) {
    width:90%;
    margin: 0 auto;
  
    
  }
 
  @media (max-width: 500px) {
    width:100%;
    margin: 0 auto;
    
    
  }
  
`;

import React from "react";
import {
  PolitiqueDeConfidentialiteContainerStyled,
  PolitiqueDeConfidentialiteImageContainerStyled,
  PolitiqueDeConfidentialiteSectionStyled,
} from "../styles/sections/PolitiqueDeConfidentialite.styled";
import ImageAnimAlice from "../../assets/images/section/hero-section/Animalice.png";
import { CTAButtonStyled } from "../styles/common/CTAButton.styled";
export default function PolitiqueDeConfidentialite() {
  return (
    <PolitiqueDeConfidentialiteSectionStyled>
      <PolitiqueDeConfidentialiteContainerStyled>
        <PolitiqueDeConfidentialiteImageContainerStyled>
          <img src={ImageAnimAlice} width="300" height="300" alt="AnimAlice" />
        </PolitiqueDeConfidentialiteImageContainerStyled>
        <h1>Politique de confidentialité d'Animalice.be</h1>
        <p>
          AnimAlice s'engage à protéger les informations personnelles des
          utilisateurs. Cette politique de confidentialité explique comment
          AnimAlice collecte, utilise, stocke et protège les informations
          personnelles qu'AnimAlice reçoit lorsque vous utilisez le formulaire
          de contact par e-mail.
        </p>
        <h2>Collecte d'informations personnelles</h2>
        <p>
          Lorsque vous utilisez le formulaire de contact par e-mail, vos
          informations personnelles, telles que votre nom, prénom, et votre
          adresse e-mails sont collectées.
        </p>

        <h2>Utilisation des informations personnelles</h2>
        <p>
          Les informations personnelles sont uniquement utilisées dans le cadre
          de l'activité Animalice.be.
        </p>

        <h2>Divulgation des informations personnelles</h2>
        <p>
          AnimAlice ne divulgue <strong>pas</strong> vos informations
          personnelles à des tiers.
        </p>

        <h2>Sécurité des informations personnelles</h2>
        <p>
          AnimAlice prend des mesures appropriées pour protéger vos informations
          personnelles contre la perte, l'utilisation abusive, l'accès non
          autorisé, la divulgation, l'altération ou la destruction, grâce à un
          certificat SSL, et une boîte mail sécurisée qui stocke les
          informations personnelles lors de l'envoi de vos informations.
        </p>
        <h2>Durée de conservation des informations personnelles</h2>
        <p>
          AnimAlice ne conserve vos informations personnelles que le temps
          nécessaire pour réaliser les finalités pour lesquelles elles ont été
          collectées. Cela signifie que vos informations personnelles seront
          conservées pendant la durée nécessaire pour répondre à votre demande,
          après quoi elles seront supprimées de nos systèmes.
        </p>

        <h2>Modifications de la politique de confidentialité</h2>
        <p>
          AnimAlice se réserve le droit de modifier cette politique de
          confidentialité à tout moment. Les modifications prendront effet dès
          leur publication sur le site web.
        </p>

       

        <h2>Dernière modification de la politique de confidentialité</h2>
<p>Cette politique de confidentialité a été mise à jour le 5 mars 2023.</p>

<div>
          <CTAButtonStyled primary href="/">
            {" "}
            Retour sur le site
          </CTAButtonStyled>
        </div>

      </PolitiqueDeConfidentialiteContainerStyled>
    </PolitiqueDeConfidentialiteSectionStyled>
  );
}

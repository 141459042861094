import React from "react";
import { useLottie } from "lottie-react";

const AnimatedImageJson = ({ imageName, section, darkMode }) => {

  if (darkMode && imageName === "cotton_swabs") {
    imageName = "cotton_swabs_dark";
  } else if (!darkMode && imageName === "cotton_swabs") {
    imageName = "cotton_swabs";
  }

  if (darkMode && imageName === "loading_paw") {
    imageName = "loading_paw_dark";
  } else if (!darkMode && imageName === "loading_paw") {
    imageName = "loading_paw";
  }



  const imageNameAnimation = require(`../../../assets/images/section/${section}/${imageName}`);



  const options = {
    animationData: imageNameAnimation,
    loop: true,
   
  };

  const { View } = useLottie(options);

  return <>{View}</>;
};

export default AnimatedImageJson;

import React from "react";
import {
  LoadingContainerStyled, 


} from "../styles/layout/Loading.styled";
import LoadingImg from "../../assets/images/loading/loading.png";

const Loading = () => {



  return (
    <LoadingContainerStyled>
      
    

        <img src={LoadingImg} alt="AnimAlice Logo" />
     

      
    </LoadingContainerStyled>
  );
};

export default Loading;

import React from 'react';
import Wave from 'react-wavify';
import styled from 'styled-components';

export const WaveSeparatorServicesAndTarifsContainer = styled.div`

  background-color: ${({ theme }) => theme.colors.servicesSectionBackground};
`;
function WaveSeparatorServicesAndTarifs( {theme, darkMode }) {
  return (
  
    <div style={{ position: 'relative', top: '20px', backgroundColor: 'transparent' }}>
      <Wave
         fill={darkMode ? theme.colors.tarifsSectionBackground : theme.colors.tarifsSectionBackground}
        paused={false}
        options={{
          height: 20,
          amplitude: 10,
          speed: 0.2,
          points: 5,
          wavelength: 100,
       
          

          
        }}
      />
  
  </div>
  
  );
}

export default WaveSeparatorServicesAndTarifs;
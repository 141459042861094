export const NavItems = [
  {
    isProfile: true,
  },
  {
    href: "#about",
    text: "A propos",
  },

  {
    href: "#services",
    text: "Services",
  },
  {
    href: "#tarifs",
    text: "Tarifs",
  },
  {
    href: "#galerie",
    text: "Galerie",
  },
  {
    href: "#contact",
    text: "Me contacter",
  },

  {
    hasSocialIcons: true,
  },
  {
    hasCTA: true,
  },
];

import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

*,
*::before,
*::after{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}




html {
    
    font-family: 'Roboto Mono', monospace;
    scroll-behavior: smooth;
    color: ${({ theme }) => theme.colors.textColorHtml};
    background: ${({ theme }) => theme.colors.htmlBackground};
    font-size: 12px;
    overflow-x:hidden;
}

body{
    @media (max-width: ${({ theme }) => theme.mobile}) {
        overflow-x:hidden;
    }
}



a{
    text-decoration: none;
    color: ${({ theme }) => theme.colors.aLink};
    transition: all 250ms ease-in-out;

    &:hover{
        color: ${({ theme }) => theme.colors.aLinkHover};
    }
}


ul {
    list-style: none;
}

hr {
    background: linear-gradient(
        to right,
        ${({ theme }) => theme.colors.lineHrOne} 0%,
        ${({ theme }) => theme.colors.lineHrTwo} 100%
    );
    border: 0;
    height: 2px;
    border-radius:50px;
  
}


// Custom ScrollBar

::-webkit-scrollbar{
    width:15px;
    @media (max-width: 1150px) {
        display:none;
    }
}
::-webkit-scrollbar-track{
    background: ${({theme}) => theme.colors.webkitScrollbarTrack};
  
}
::-webkit-scrollbar-thumb{
    background: ${({theme}) => theme.colors.webkitScrollbarThumb};
    border-radius:15px;

}
::-webkit-scrollbar-thumb:hover{
    background: ${({theme}) => theme.colors.webkitScrollbarThumbHover};
}




`;

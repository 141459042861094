import styled from "styled-components";

export const SectionHeadingStyled = styled.div`
    text-align: center;
    margin-bottom: 0rem;
    width:100%;
    align-items:center;

    h1 {
        //font-family: 'Finger Paint', cursive;
        font-family: 'Amatic SC', cursive;
        font-weight:700;
        text-transform: uppercase;
        display: inline-block;
        font-size: 8rem;
        margin: 0rem 0 0rem 0;
        position: relative;

       
    }
    h2 {
        //font-family: 'Finger Paint', cursive;
        font-family: 'Roboto Mono', monospace;
        font-weight:700;
        text-transform: uppercase;
        display: inline-block;
        font-size: 5rem;
        margin: 0rem 0 0rem 0;
        position: relative;

       
    }
    

    p {
        font-family: "Poppins", sans-serif;
        font-size: 16px;
        margin-bottom: 5rem;
        margin-top:3rem;
    }

    @media (max-width: ${({theme}) => theme.mobile}){
        
 
        margin-bottom: 0rem;
        margin-top:0rem;
        h1 {
            
        }
        p{
            
        }
      
    }

`
import React from "react";
import { CTAButtonStyled } from "../styles/common/CTAButton.styled";
import {
  MentionsLegalesSectionStyled,
  MentionsLegalesContainerStyled,
  MentionsLegalesImageContainerStyled,
} from "../styles/sections/MentionsLegales.styled";
import ImageAnimAlice from "../../assets/images/section/hero-section/Animalice.png";
export default function MentionsLegalesSection() {
  return (
    <MentionsLegalesSectionStyled>
      <MentionsLegalesImageContainerStyled>
      <img src={ImageAnimAlice} width="300" height="300" alt="AnimAlice" />
      </MentionsLegalesImageContainerStyled>
     
      <MentionsLegalesContainerStyled>
        <h1>Mentions légales</h1>

        <h2>Propriété du site</h2>

        <h3>Nom de l’entreprise</h3>
        <p>AnimAlice</p>

        <h3>Forme juridique </h3>
        <p>Personne physique</p>

        <h3>Fondatrice</h3>
        <p>Alice Decroly Duart</p>

        <h3>Numéro de téléphone</h3>
        <p>+32 496 22 30 00</p>

        <h3>E-mail</h3>
        <p>contact@animalice.be </p>

        <h3>Numéro d’entreprise</h3>
        <p>BE 0780.742.409</p>

        <h2>Hébergeur</h2>
        <h3>
          Nom </h3>
         
        <p> <a href="https://www.hostinger.fr/">Hostinger</a></p>
        <h3>Siège social </h3>
        <p>Kaunas, Lituanie Hostinger, UAB Jonavos g. 60C, 44192</p>

        <h2>Design et développement</h2>
        <h3>Coordonnées</h3>
        <p>Gregory De Meur</p>
        <p><a href="mailto:contact@gregorydemeur.be">contact@gregorydemeur.be</a></p>
        <p><a href="https://www.gregorydemeur.be">https://gregorydemeur.be</a></p>
        <br />
        
        <div>
          <CTAButtonStyled primary href="/">
            {" "}
            Retour sur le site
          </CTAButtonStyled>
        </div>
      </MentionsLegalesContainerStyled>
    </MentionsLegalesSectionStyled>
  );
}

import { useState, useEffect, forwardRef, useRef } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import TarificationSimulator from "./TarifsSectionSimulator";
import WaveSeparatorTarifsAndGalerie, {
  WaveSeparatorTarifsAndGalerieContainer,
} from "../layout/WaveSeparatorTarifsAndGalerie";
import { SectionHeadingStyled } from "../styles/common/SectionHeading.style";
import TarifsMai2024 from "../../assets/images/tarifs/tarifs_animalice_2024.png"
import {
  TarifsSectionStyled,
  TarifsContainerStyled,
  PriceStyled,
  ServiceNameStyled,
  AccordionContentStyled,
  AccordionHeaderStyled,
  AccordionWrapperStyled,
  FaAngleUpStyled,
  FaAngleDownStyled,
  TarifsImage,
} from "../styles/sections/TarifsSection.styled";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import { BsQuestionCircleFill } from "react-icons/bs";
import { CustomHRWithIcon } from "../styles/common/HrBar.styled";
import { FaEuroSign } from "react-icons/fa";

const Infobulle = forwardRef((props, ref) => {
  return (
    <Tippy content={props.content} arrow={true} theme="dark">
      <span ref={ref}>
        <BsQuestionCircleFill />
      </span>
    </Tippy>
  );
});

export const TarifsSection = ({ theme, darkMode, props }) => {
  const [accordionOpen, setAccordionOpen] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1150);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const imageRef = useRef(null);

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      // Entrer en mode plein écran
      if (imageRef.current) {
        if (imageRef.current.requestFullscreen) {
          imageRef.current.requestFullscreen();
        } else if (imageRef.current.webkitRequestFullscreen) {
          /* Safari */
          imageRef.current.webkitRequestFullscreen();
        } else if (imageRef.current.msRequestFullscreen) {
          /* IE11 */
          imageRef.current.msRequestFullscreen();
        }
      }
    } else {
      // Quitter le mode plein écran
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }

    setIsFullscreen(!isFullscreen);
  };
  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 80,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 1150);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);



  return (
    <>
      <WaveSeparatorTarifsAndGalerieContainer>
        <TarifsSectionStyled id="tarifs" data-aos="fade-right">
          <TarifsContainerStyled>
            <SectionHeadingStyled>
              <h2>Tarifs</h2>
            </SectionHeadingStyled>
            <CustomHRWithIcon icon={FaEuroSign}/>
            <TarifsImage onClick={toggleFullscreen}
              className={isFullscreen ? "fullscreen" : ""}>
              <img ref={imageRef} src={TarifsMai2024} alt="Tarifs Mai 2024" className={isFullscreen ? "fullscreen" : ""} />
            </TarifsImage>
            {/* <TarificationSimulator /> */}
          </TarifsContainerStyled>
        </TarifsSectionStyled>

        <div>
          <WaveSeparatorTarifsAndGalerie theme={theme} darkMode={darkMode} />
        </div>
      </WaveSeparatorTarifsAndGalerieContainer>
    </>
  );
};

import styled from "styled-components";

import {AiOutlineCopyright} from "react-icons/ai";
import {BsSuitHeartFill} from "react-icons/bs";

export const FooterCopyrightSectionStyled = styled.section`
  max-height: 50px;
  width: 100%;
  padding:1rem;
  
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.footerCopyrightSectionBackground};
  text-align:center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding:1rem;
    
  }

  a {
    color: white;

    &:hover {
      color : ${({ theme }) => theme.colors.aLinkHover};
    }
  }
 
`;

export const FooterContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
 
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
  
  color: ${({ theme }) => theme.colors.footerText};
 
  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
  }
`;

export const IconContainerStyled = styled.div`
  display: flex;
  align-items: center;
  font-size:11px;
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    
  }
`;




export const CopyrightIconStyled = styled(AiOutlineCopyright)`
  
  font-size:12px;
  
`;

export const HeartIconStyled = styled(BsSuitHeartFill)`
  margin-right:8px;
  font-size:12px;
  color: red;
 
`;



    

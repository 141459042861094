import {
  FooterContainerStyled,
  FooterSectionStyled,
  IconContainerStyled,
  PositionIconStyled,
  FacebookIconStyled,
  PhoneIconStyled,
  MailIconStyled,
  
} from "../styles/sections/FooterSection.styled";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

export const FooterSection = () => {
  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 150,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
  }, []);

  return (
    <>
      <FooterSectionStyled>
        <FooterContainerStyled>
          <IconContainerStyled>
         
          <PositionIconStyled />
            
              <span>
                Quartier Bon Air
                <br />
                7900 Leuze-en-Hainaut
              </span>
         
          </IconContainerStyled>
          <IconContainerStyled>
          <a href="tel:+32498973216">
          <PhoneIconStyled />
            
            
              <span>0498/97.32.16</span>
            </a>
          </IconContainerStyled>
          <IconContainerStyled>
          <a href="mailto:contact@animalice.be">
          <MailIconStyled />
            
            
              <span>contact@animalice.be</span>
            </a>
          </IconContainerStyled>
          <IconContainerStyled>
          <a href="https://www.facebook.com/AnimAlice.be/" target="_blank" rel="noopener noreferrer">
          <FacebookIconStyled />
 
 
    <span>AnimAlice</span>
  </a>
</IconContainerStyled>

        </FooterContainerStyled>
  
        
      </FooterSectionStyled>
    </>
  );
};

import styled from "styled-components";
export const MentionsLegalesSectionStyled = styled.section`
  min-height: 100vh;
  width: 100%;
  padding: 5rem 0;
  background-color: ${({ theme }) =>
    theme.colors.mentionsLegalesSectionBackground};
  text-align: center;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 6rem 0 5rem;
    text-align: center;
  }
`;

export const MentionsLegalesContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;

  h1 {
    font-size: 4.8rem;
    margin-bottom: 3rem;
    color: ${({ theme }) => theme.colors.mentionsLegalesh1};
  }

  h2 {
    font-size: 2.9rem;
    margin: 7rem 0 1rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesh2};
  }

  h3 {
    font-size: 2.4rem;
    margin-top: 2.5rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesh3};
  }

  p {
    font-size: 2rem;
    margin: 1.2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.mentionsLegalesParagraphe};
  }
  a{
    color: ${({ theme }) => theme.colors.mentionsLegalesParagraphe};
  }
  a:hover {
    color:${({ theme }) => theme.colors.mentionsLegalesHover};
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 0;

    h2 {
      margin-top: 6rem;
    }
  }
`;

export const MentionsLegalesImageContainerStyled = styled.div`
  margin: 0 auto;
  justify-content: center;
  text-align: center;
  display: flex;
`;

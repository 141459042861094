import styled from "styled-components";

export const AboutSectionStyled = styled.section`
  min-height: 102vh;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-right: 10rem;
  padding-left: 10rem;
  padding-bottom: 10rem;
  padding-top: 8rem;
  background-color: ${({ theme }) => theme.colors.aboutSectionBackground};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-right: 0;
    padding-left: 0;
    padding-bottom: 3rem;
    padding-top: 5rem;

    p {
      
    }

    h1 {
      font-size: 3rem;
    }
    h3 {
      font-size: 3rem;
    }
  }
`;

export const AboutContentStyled = styled.div`
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  color: ${({ theme }) => theme.colors.aboutSectionTextTitle};

  p {
    /* font-family: "Poppins", sans-serif; */
    font-size: 1.6rem;
    text-align: justify;
    margin-bottom: 1.45rem;
    color: ${({ theme }) => theme.colors.aboutSectionTextContent};
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size: 1.3rem;
      margin-bottom: 1.45rem;
    }
  }
`;

export const HeadingStyled = styled.div`
  font-size: 3rem;
  margin-bottom: 20px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 2rem;
  }
`;

export const AboutContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding: 2rem;
    justify-content: center;
  }
`;

export const AboutContainerImageAndTextStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: auto;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
  }
`;

export const AboutContainerTextStyled = styled.div`
  width: 100%;
  height: auto;

  padding: 8rem;
  background-color: ${({ theme }) =>
    theme.colors.aboutSectionContainerTextBackground};
  margin-left: 5rem;
  border-radius: 10px;

  /* Ajout de la propriété transform pour l'effet de perspective */

  transition: all 0.2s ease-out;

  h1 {
    font-size: 4rem;
  }
  h3 {
    font-size: 4rem;
  }
  @media (max-width: ${({ theme }) => theme.mobile}) {
    /* width: 90%; */
    margin: 0 auto;
    padding: 20px;
    margin-top: 23rem;
  }
`;

export const ImageStyled = styled.img`
  height: auto;
  width: 38rem;
  border-radius: 5%;
  position: absolute;
  z-index: 4;
  border: solid
    ${({ theme }) => theme.colors.aboutSectionContainerTextBackground} 1px;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
    margin: 0 auto;
  }
`;

export const ImageContainerStyled = styled.div`
  height: auto;
  width: 38rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 2.5rem;
  position: relative;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: auto;
    width: 31rem;
    margin: 0 auto;
    margin-top: 18rem;
  }
`;


import React, { useRef, useState, useEffect, forwardRef } from "react";
import emailjs from "@emailjs/browser";
import { SectionHeadingStyled } from "../styles/common/SectionHeading.style";
import {
  ContactContainerStyled,
  ContactFormAndTextContainer,
  ContactFormStyled,
  ContactSectionStyled,
  ContactTextStyled,
  SEOParagrapheStyled,
} from "../styles/sections/ContactSection.styled";
import { CustomHRWithIcon } from "../styles/common/HrBar.styled";
import { FiMail } from "react-icons/fi";
import { BsQuestionCircleFill } from "react-icons/bs";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

import AnimatedImageJson from "./ServicesVectors/AnimatedImageJson";


import styled from "styled-components";

export const AlertStyled = styled.div`
  position: fixed;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  padding: 30px;

  border-radius: 10px;
  z-index: 9999;
  width: 80%;
  text-align: center;
  font-size: 18px;

  &.alert-success {
    color: #155724;
    background-color: #d4edda;
    border-color: #c3e6cb;
  }

  &.alert-danger {
    color: #721c24;
    background-color: #f8d7da;
    border-color: #f5c6cb;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    font-size:13px;
    width:80%;
    padding:20px;
    top: 10%;
   
  }

 
`;

export const LoadingIndicatorStyled = styled.div`
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);

  z-index: 9999;

  width: 60%;
`;

export const LoadingTextStyled = styled.div`
  position: fixed;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
 
  z-index: 9999;
  p {
    font-size: 18px;
    @media (max-width: ${({ theme }) => theme.mobile}) {
      font-size:13px;
    }
  }
`;

export const ModalStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${({theme}) => theme.colors.modalSendForm};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9998;

`;


export const ContactSection = ({theme, darkMode}) => {
  const Infobulle = forwardRef((props, ref) => {
    return (
      <Tippy content={props.content} arrow={true} theme="dark">
        <span ref={ref}>
          <BsQuestionCircleFill />
        </span>
      </Tippy>
    );
  });

  const form = useRef();
  const [formSent, setFormSent] = useState(false);
  const [message, setMessage] = useState("");
  const [sending, setSending] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    setSending(true);
    // Vérifie que la case à cocher est cochée
    if (!form.current.consent.checked) {
      setMessage(
        "Veuillez accepter la collecte et le traitement de vos données personnelles."
      );
      setSending(false);
      return;
    }

     // Ajoute le champ "Reply-To" à l'en-tête de l'e-mail
  const headers = {
    "Reply-To": form.current.user_email.value
  };

    emailjs
      .sendForm(
        "service_ptfuaua",
        "template_pw5nc9j",
        form.current,
        "PvmeA0ZQ2sc3iAlPw",
        headers // Passe le header en paramètre à la méthode sendForm()
      )
      .then(
        (result) => {
          setFormSent(true);
          setMessage(
            "Votre message a bien été envoyé ! Merci, je vous recontacterai dès que possible !"
          );
          form.current.reset();
        },
        (error) => {
          setFormSent(false);
          setMessage(
            "Une erreur s'est produite. Veuillez réessayer plus tard."
          );
        }
      )
      .finally(() => {
        setSending(false);
      });
  };

  useEffect(() => {
    if (formSent) {
      const timer = setTimeout(() => {
        setFormSent(false);
        setMessage("");
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [formSent]);

  return (
    <ContactSectionStyled id="contact">
      <ContactContainerStyled>
        <SectionHeadingStyled data-aos="fade-right">
          <h2>Contact</h2>
        </SectionHeadingStyled>
        <CustomHRWithIcon icon={FiMail} />
        <ContactFormAndTextContainer>
          <ContactTextStyled data-aos="fade-up">
            <div>
              <p>
                Si vous êtes à la recherche de <strong>soins</strong> pour votre{" "}
                <strong>animal</strong> de compagnie dans la région de{" "}
                <strong>
                  Leuze-en-Hainaut ou ses alentours{" "}
                  <Infobulle content="Comme Bury, Roucourt, Basècles, Quevaucamps, Beloeil, Huissignies, Ormeignies, Mainvault, Frasnes-lez-Buissenal, Villers-Saint-Amand,Blicquy, Péruwelz, Gallaix, Pipaix, Thieulain, Grandmetz, Chapelle-à-Wattines, Chapelle-à-Oie." />
                </strong>
                , n'hésitez pas à me <strong>contacter</strong>.
              </p>
              <p>
                Je suis prête à répondre à vos questions et à fournir des soins
                attentionnés à votre animal. Mes services sont disponibles dans
                un{" "}
                <strong>
                  rayon maximum de 20 km autour de Leuze-en-Hainaut
                </strong>
                . Je me ferai un plaisir de discuter de vos besoins spécifiques
                et de trouver une{" "}
                <strong>solution adaptée à votre animal</strong>.
              </p>
            </div>
            <SEOParagrapheStyled>
              Mes{" "}
              <strong>
                services AnimAlice, aide-soignante vétérinaire (ASV) à domicile
              </strong>
              , sont disponibles dans un{" "}
              <strong>rayon de 20 km autour de Leuze-en-Hainaut</strong>,
              couvrant les villes et communes voisines telles que Bury,
              Roucourt, Basècles, Quevaucamps, Beloeil, Huissignies, Ormeignies,
              Mainvault, Frasnes-lez-Buissenal, Villers-Saint-Amand, Blicquy,
              Péruwelz, Gallaix, Pipaix, Thieulain, Grandmetz,
              Chapelle-à-Wattines, Chapelle-à-Oie.
            </SEOParagrapheStyled>
          </ContactTextStyled>
          <ContactFormStyled data-aos="fade-up">
            <form ref={form} onSubmit={sendEmail}>
              <label>Nom et prénom</label>
              <input type="text" name="user_name" required />
              <label>Email</label>
              <input type="email" name="user_email" required />
              <label>Motif</label>
              <input type="text" name="user_motif" required />
              <label>Message</label>
              <textarea name="message" required />
              <label>
                <input type="checkbox" name="consent" required />
                En cochant cette case, j'accepte que mes données personnelles
                soient collectées et traitées dans le cadre de l'activité
                d'AnimAlice.
              </label>

              

              {!sending && (
                <input type="submit" disabled={sending} value="Envoyer" />
              )}
            </form>
           
          </ContactFormStyled>
        </ContactFormAndTextContainer>
       
        {sending && (
                 <ModalStyled>
                 <LoadingIndicatorStyled>
                   <AnimatedImageJson
                     imageName= "loading_paw"
                     section="contact-section"
                     theme={theme}
                     darkMode={darkMode}
                   />
         
                   <LoadingTextStyled>
                     <p>Envoi en cours...</p>
                   </LoadingTextStyled>
                 </LoadingIndicatorStyled>
                 </ModalStyled>
              )}
               {formSent && (
              <AlertStyled className="alert-success">
                <p>{message}</p>
              </AlertStyled>
            )}
            {!formSent && message && (
              <AlertStyled className="alert-danger">
                <p>{message}</p>
              </AlertStyled>
            )}

      </ContactContainerStyled>
    </ContactSectionStyled>
   
  );
};
export default ContactSection;

import React from 'react';
import Wave from 'react-wavify';
import styled from 'styled-components';

export const WaveSeparatorAboutAndServicesContainer = styled.div`

  background-color: ${({ theme }) => theme.colors.aboutSectionBackground};
`;
function WaveSeparatorAboutAndServices( {theme, darkMode }) {
  return (
  
    <div style={{ position: 'relative', top: '20px', backgroundColor: 'transparent' }}>
      <Wave
         fill={darkMode ? theme.colors.servicesSectionBackground : theme.colors.servicesSectionBackground}
        paused={false}
        options={{
          height: 80,
          amplitude: 5,
          speed: 0.2,
          points: 5,
          wavelength: 100,
       
          

          
        }}
      />
  
  </div>
  
  );
}

export default WaveSeparatorAboutAndServices;
import React, { useState, useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga";
import AnimatedImageJson from "./components/sections/ServicesVectors/AnimatedImageJson";

function GoogleAnalytics() {
  const [gaEnabled, setGAEnabled] = useState(false);
  const [showCookieBanner, setShowCookieBanner] = useState(false);
  useEffect(() => {
    if (gaEnabled) {
      ReactGA.initialize("G-49KRFPK6LT");
      ReactGA.pageview(window.location.pathname + window.location.search);
    }
  }, [gaEnabled]);

  useEffect(() => {
    setTimeout(() => {
      setShowCookieBanner(true);
    }, 5000);
  }, []);

  return (
    <>
      {showCookieBanner && (
        <CookieConsent
          position={"top"}
          onAccept={() => setGAEnabled(true)}
          onDecline={() => setGAEnabled(false)}
          enableDeclineButton={true}
          declineButtonText="Refuser"
          buttonText="Accepter"
          buttonWrapperClasses="cookie-button-wrapper"
        >
          <div className="cookie-message">
            <AnimatedImageJson
              imageName="cookie"
              section="cookie"
              className="cookie-image"
            />
            <span>
              Ce site utilise des cookies pour améliorer votre expérience
              utilisateur. Ces cookies recueillent des données sur les visites
              du site. Les informations collectées sont anonymes et ne sont pas
              utilisées pour vous identifier personnellement. En cliquant sur «
              Accepter », vous consentez à l'utilisation de ces cookies.
            </span>
          </div>
        </CookieConsent>
      )}

      <style>
        {`
          .cookie-button-wrapper {
        
          }

          .cookie-message {
            display: flex;
            align-items: center;
            font-size:1rem;

            padding:0;
            
            svg {
         width:1000px;
      
              @media (max-width: 1150px) {
           
                padding:1rem;
              }

            }
       
       
        }
        `}
      </style>
    </>
  );
}
export default GoogleAnalytics;

import {
  FooterContainerStyled,

  IconContainerStyled,
 

} from "../styles/sections/FooterCopyrightSection.styled";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { CopyrightIconStyled, FooterCopyrightSectionStyled, HeartIconStyled } from "../styles/sections/FooterCopyrightSection.styled";

export const FooterCopyrightSection = ({ theme, darkMode }) => {
  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 150,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
  }, []);

  return (
    <>
      <FooterCopyrightSectionStyled>
        <FooterContainerStyled>
         
         <IconContainerStyled>
         <p><CopyrightIconStyled /> 2024 AnimAlice -  <a href="/mentions-legales">Mentions légales</a> - <a href="/politique-de-confidentialite">Politique de confidentialité</a> - Made with <span> &nbsp;<HeartIconStyled/></span>by <a href="https://www.gregorydemeur.be">GDM</a></p>
         </IconContainerStyled>
        

        </FooterContainerStyled>
  
        
      </FooterCopyrightSectionStyled>
    </>
  );
};

import { SectionHeadingStyled } from "../styles/common/SectionHeading.style";
import { GalerieSectionStyled } from "../styles/sections/GalerieSection.styled";
import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import PhotoAlbum from "react-photo-album";
import jsonData from "../../data/galerieData.json";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Slideshow from "yet-another-react-lightbox/plugins/slideshow";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import WaveSeparatorGalerieAndContact, {
  WaveSeparatorGalerieAndContactContainer,
} from "../layout/WaveSeparatorGalerieAndContact";
import { CTAButtonStyled } from "../styles/common/CTAButton.styled";
import { CustomHRWithIcon } from "../styles/common/HrBar.styled";
import { MdPhotoCamera } from "react-icons/md";

const GalerieSection = ({ theme, darkMode }) => {
  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 50,
      duration: 600,
      easing: "ease-in-out",
      delay: 100,
    });
  }, []);

  const images = jsonData.images.map((image) => ({
    src: require(`../../assets/images/section/galerie-section/${image.url}`),
    width: image.width,
    height: image.height,
    title: image.title,
    description: image.description,
    alt: image.title,
  }));

  // Afficher seulement les 4 premières images
  const initialImages = images.slice(0, 8);

  const [index, setIndex] = useState(-1);
  const [moreImages, setMoreImages] = useState(false);

  const handleButtonClick = () => {
    setMoreImages(true);
  };

  return (
    <WaveSeparatorGalerieAndContactContainer>
      <GalerieSectionStyled id="galerie" data-aos="fade-right">
        <SectionHeadingStyled>
          <h2>Galerie</h2>
        </SectionHeadingStyled>
        <CustomHRWithIcon icon={MdPhotoCamera}/>
        <div data-aos="fade-up">
          <PhotoAlbum
            layout="masonry"
            photos={moreImages ? images : initialImages}
            onClick={({ index }) => setIndex(index)}
          />
          {!moreImages && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CTAButtonStyled primary onClick={handleButtonClick} margin="20px 0 0 0">
                Afficher plus de photos
              </CTAButtonStyled>
            </div>
          )}
          <Lightbox
            slides={images}
            open={index >= 0}
            index={index}
            close={() => setIndex(-1)}
            plugins={[Fullscreen, Slideshow, Thumbnails, Zoom, Captions]}
            />
            </div>
            </GalerieSectionStyled>
            <div>
            <WaveSeparatorGalerieAndContact theme={theme} darkMode={darkMode} />
            </div>
            </WaveSeparatorGalerieAndContactContainer>
            );
            };
            
            export default GalerieSection;

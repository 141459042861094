import { SectionHeadingStyled } from "../styles/common/SectionHeading.style";
import {
  ServiceBannerStyled,
  ServiceDescriptionStyled,
  ServiceImageContainer,
  ServicesContainerStyled,
  ServicesSectionStyled,
  ServiceTitleStyled,
  ServiceTextContainer,
} from "../styles/sections/ServicesSection.styled";
import AnimatedImageJson from "./ServicesVectors/AnimatedImageJson";
import React, { useEffect, useState } from "react";

import servicesData from "../../data/servicesData.json";

import AOS from 'aos';
import 'aos/dist/aos.css'; 
import WaveSeparatorServicesAndTarifs, { WaveSeparatorServicesAndTarifsContainer } from "../layout/WaveSeparatorServicesAndTarifs";
import { CustomHRWithIcon } from "../styles/common/HrBar.styled";

import { MdMedicalServices } from "react-icons/md";


export const ServicesSection = ({theme, darkMode}) => {

  useEffect(() => {
    // Initialise AOS
    AOS.init({
      offset: 50,
      duration: 600,
      easing: 'ease-in-out',
      delay: 100,
    });
  
    // Détermine si l'utilisateur est sur mobile ou non
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1150);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Appel initial pour initialiser la variable
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [isMobile, setIsMobile] = useState(false);
  const [isImageLeft, setIsImageLeft] = useState(false);






  const servicesContent = servicesData.services.map((service, index) => {
    const imageElement = (
      <ServiceImageContainer data-aos={service.animationImage}>
        <AnimatedImageJson imageName={service.image} section={service.imageSection} theme={theme} darkMode={darkMode}/>
      </ServiceImageContainer>
    );
    const textElement = (
      <ServiceTextContainer data-aos={service.animationText}>
        <ServiceTitleStyled>{service.title}</ServiceTitleStyled>
        <ServiceDescriptionStyled
          dangerouslySetInnerHTML={{
            __html: servicesData.services[index].description,
          }}
        ></ServiceDescriptionStyled>
      </ServiceTextContainer>
    );

    return (
      <ServiceBannerStyled key={service.id}>
      {isMobile ? (
        <>
          {imageElement}
          {textElement}
        </>
      ) : (
        <>
          {index % 2 === 0 ? (
            <>
              {imageElement}
              {textElement}
            </>
          ) : (
            <>
              {textElement}
              {imageElement}
            </>
          )}
        </>
      )}
    </ServiceBannerStyled>
      
    );
   

  

  });

  useEffect(() => {
    setIsImageLeft(!isImageLeft);
  }, []); // la dépendance vide garantit que l'effet ne sera exécuté qu'une seule fois
 
  
  return (
    <>
    <WaveSeparatorServicesAndTarifsContainer>

   
      <ServicesSectionStyled id="services" data-aos="fade-right">
        <ServicesContainerStyled>
          <SectionHeadingStyled>
            <h2>Services</h2>
          </SectionHeadingStyled>
          <CustomHRWithIcon icon={MdMedicalServices}/>
        
          {servicesContent}
        </ServicesContainerStyled>
      </ServicesSectionStyled>

     <div><WaveSeparatorServicesAndTarifs theme={theme} darkMode={darkMode}/></div>

      </WaveSeparatorServicesAndTarifsContainer>
    </>
  );
};
import React from 'react'


import PolitiqueDeConfidentialite from '../sections/PolitiqueDeConfidentialiteSection'

export default function PolitiqueDeConfidentialitePage({theme, setDarkMode, darkMode}) {
  return (
 
    
      <PolitiqueDeConfidentialite />
   
  )
}


import { NavBar } from "../../components/layout/NavBar.jsx";
import React, { useState, useEffect } from "react";

import { HeroSection } from "../../components/sections/HeroSection.jsx";
import Loading from "../../components/layout/Loading";
import { AboutSection } from "../../components/sections/AboutSection.jsx";

import { TarifsSection } from "../../components/sections/TarifsSection.jsx";
import { ServicesSection } from "../../components/sections/ServicesSection.jsx";

import { LineSeparator } from "../layout/LineSeparatorSection";
import GalerieSection from "../../components/sections/GalerieSection.jsx";
import ContactSection from "../../components/sections/ContactSection.jsx";
import { FooterSection } from "../../components/sections/FooterSection.jsx";
import { FooterCopyrightSection } from "../../components/sections/FooterCopyrightSection.jsx";
import TestimonialsSection from "../../components/sections/TestimonialsSection.jsx";
import GoogleAnalytics from "../../google-analytics.js";


function HomePage(props) {
  
    const { darkMode, setDarkMode, theme} = props;
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => setLoading(false), 1200);
  }, []);

  return (
    <>
  
        {loading ? (
          <Loading />
        ) : (
          <>
          <GoogleAnalytics />
            <NavBar
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
           
            <HeroSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <LineSeparator />
            <AboutSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />

            <ServicesSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <TarifsSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <GalerieSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <ContactSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <TestimonialsSection
              theme={theme}
              setDarkMode={setDarkMode}
              darkMode={darkMode}
            />
            <hr />
            <footer>
              <FooterSection
                theme={theme}
                setDarkMode={setDarkMode}
                darkMode={darkMode}
              />
              <FooterCopyrightSection />
            </footer>
          </>
        )}
    
    </>
  );
}

export default HomePage;

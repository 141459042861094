import styled, { keyframes } from "styled-components";


const logoAnimationStyled = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translateY(0);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-50px);
  }
  60% {
    opacity: 1;
    transform: translateY(0);
  }
  80% {
    opacity: 0;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(0);
  }
`;

const logoAnimationMobileStyled = keyframes`
  0% {
    opacity: 0;
    transform: scale(0) translateY(-50px);
  }
  30% {
    opacity: 1;
    transform: scale(1) translateY(-100px);
  }
  60% {
    opacity: 1;
    transform: translateY(-50px);
  }
  80% {
    opacity: 0;
    transform: translateY(-50px);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
`;






export const LoadingContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: ${({theme}) => theme.colors.htmlBackground };
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;

  img {
    height: 500px;
    width:500px;
    animation: ${logoAnimationStyled} 1.5s ease-in-out;
    
    
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    img {
      position: absolute;
      
      
      height: 300px;
      width: 300px;
      animation: ${logoAnimationMobileStyled} 1.5s ease-in-out;

    
  }

  }


  h1 {
    font-size: 2rem;
    margin-top: 1rem;
    animation: ${logoAnimationStyled} 1.5s ease-in-out;
  }


`;



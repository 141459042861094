import styled from 'styled-components';

const Separator = styled.div`
  .skewed {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #2c3e50;
    z-index: 0;
    transform: skewY(4deg);
    transform-origin: top right;
  }
`;

export const LineSeparator = () => {
  return (
    <>
      
      <Separator />
    </>
  );
};


import styled, { keyframes, css } from "styled-components";

const navItemFadeStyled = keyframes`
from{
opacity: 0;
transform: translateY(-100px);

}
to{
opacity: 1;
transform: translateY(20px);


}
`;

const fadein = keyframes`
  from {
    opacity: 0;
   
  }
  to {
    opacity: 1;
    
  }
`;

const navItemAnimation = ({ index }) => css`
  animation: ${navItemFadeStyled} 0.5s ease forwards ${index / 8 + 0.01}s;
`;


export const NavStyled = styled.nav`
  height: 80px;
  width: 100%;
  padding: 0 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 98;
  
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    height: 60px;
  }

  background-color: ${ ({theme}) => theme.colors.navBarColor};
  top: ${ ({scrollDirection}) => scrollDirection === "down" ? "-80px" : "0px" };

  opacity: 0;
  transition: opacity 1s ease-in-out;

  animation: ${fadein} 1s ease-in-out forwards;

  svg {
  //fill : ${ ({theme}) => theme.colors.fillSvgTheme};
 // stroke : ${ ({theme}) => theme.colors.fillSvgTheme};
  transition: 300ms ease-in-out;
  cursor:pointer;
 

/*
  &:hover{
    filter: hue-rotate(180deg) invert(100%);
    fill: ${ ({theme}) => theme.colors.swapHoverSvgTheme};
    stroke: ${ ({theme}) => theme.colors.swapHoverSvgTheme};
    
 
  }
  */
}
`;



export const NavAnimaliceStyled = styled.img`
  width: 75px;
  
  
`;

export const MenuIconStyled = styled.div`
  cursor: pointer;
  background: ${({ theme, menuOpen }) =>
    menuOpen ? "none" : theme.colors.backgroundButtonMenu};
  transition: ${({ theme }) => theme.transition};
  padding: 0.7rem;
  border-radius: 5px;
  display: none;
  position: absolute;
  right: 20px;
  z-index: 999;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: inline-block;
    right: 5px;
  }
`;

export const LineOneStyled = styled.div`
  width: 30px;
  height: 3px;
  margin: 5px 0;
  background: ${({theme}) => theme.colors.lineMobile};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ menuOpen }) =>
    menuOpen ? "rotate(-45deg) translate(-5px, 6px)" : "none"};
`;

export const LineTwoStyled = styled.div`
  width: 30px;
  height: 3px;
  margin: 5px 0;
  background: ${({theme}) => theme.colors.lineMobile};
  opacity: ${({ menuOpen }) => (menuOpen ? 0 : 1)};
  transition: ${({ theme }) => theme.transition};
`;

export const LineThreeStyled = styled.div`
  width: 30px;
  height: 3px;
  margin: 5px 0;

  background: ${({theme}) => theme.colors.lineMobile};
  transition: ${({ theme }) => theme.transition};
  transform: ${({ menuOpen }) =>
    menuOpen ? "rotate(45deg) translate(-5px, -6px)" : "none"};
`;

export const NavListStyled = styled.ul`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 2.2rem;
  flex-wrap: wrap;
  margin-right: 20px;
  
 
  

  @media (max-width: ${({ theme }) => theme.mobile}) {
    
    font-size: calc(20px + 0.5vw);
    width: 100vw;
  height: 100vh;


    background: ${({ theme }) => theme.colors.backgroundMobileMenu};
    
    top: ${({ menuOpen }) => (menuOpen ? "0" : "-100rem")};
    right:0px;
    
    position: fixed;
    
    justify-content: center;
    
    flex-direction: column;
    z-index: 998;
    transition: ${({ theme }) => theme.transition};
    margin-right: 0;
    margin: 0 auto;
    flex-wrap: nowrap;
    align-items:center;
    
  }

  
  @media only screen and (min-width: 300px) and (max-width: 1000px) and (orientation: landscape) {
   
    overflow-y:scroll;
  }






`;

export const NavItemStyled = styled.li`
  margin: 0.2rem 1.1rem;

  &::last-child {
    margin-right: 0;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    opacity: 0;
    margin: 0.2rem 0;
    margin-bottom:1.2rem;
    ${({ menuOpen }) => (menuOpen ? navItemAnimation : null)}
    
  }
`;

export const NavLinkStyled = styled.a`
font-family: 'Bebas Neue', cursive;
  
  color: ${({ theme }) => theme.colors.textNavbar};
  letter-spacing: 1.5px;

  &::after {
    content: "";
    display: block;
    height: 3px;
    width: 0%;
    background: ${({ theme }) => theme.colors.orange};
    transition: all ease-in-out 300ms;
  }
  &:hover {
    &::after {
     // underline width:100% 
    }
  }
  
`;

export const ProfileStyled = styled.div`
  display: none;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      height: 13rem;
      width: auto;
      border-radius: 50%;
      display: block;
      margin: 0rem 3rem;
      
    }
  }
  
`;
export const SocialIconsStyled = styled.div`
  display: none;
  
  @media (max-width: ${({ theme }) => theme.mobile}) {
    width: 150px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      margin: 0 0.5rem;
      font-size: 2.5rem;
  
    }
  }
`;


export const LogoAnimAliceStyled = styled.a`
  font-family: 'Finger Paint', cursive;
  font-size:3rem;
  font-weight:600;
  align-items:center;
  color: ${ ({theme}) => theme.colors.textNavbar};
  transition: all ease-in-out 100ms;
  margin-left:20px;
  transition: 300ms ease-in-out;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    align-items:center;
    font-size:2.1rem;
    margin-left:1px;
    a {
      margin: 0 0.2rem;
      
      
    }
  }
`;

export const ThemeContainer = styled.div`
position: absolute;




@media (max-width: ${({ theme }) => theme.mobile}) {
  right: 70px;
  top: 16px;
 
}






 svg {
  width:3rem;
  //fill : ${ ({theme}) => theme.colors.fillSvgTheme};
  //stroke : ${ ({theme}) => theme.colors.fillSvgTheme};
  transition: 300ms ease-in-out;
  cursor:pointer;
 /*
  &:hover{
    fill: ${ ({theme}) => theme.colors.swapHoverSvgTheme};
    stroke: ${ ({theme}) => theme.colors.swapHoverSvgTheme};
    stroke-linecap: ${ ({theme}) => theme.colors.swapHoverSvgTheme};
  }
*/
  .icon-sun {
    fill : ${ ({theme}) => theme.colors.fillSvgTheme};
  }
 
 }
`
import styled from "styled-components";
import {BsFillTelephoneFill } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import { FaFacebook } from "react-icons/fa";
import { GiPositionMarker } from "react-icons/gi";

export const FooterSectionStyled = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.footerSectionBackground};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    padding-right: 0;
    padding-left: 0;
  }

  a {
    color: white;
    display: flex;
    align-items: center;

    &:hover {
      color : ${({ theme }) => theme.colors.aLinkHover};
    }
  }
`;

export const FooterContainerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width:1400px;
  margin: 0 auto;
  font-size: 22px;
  color: ${({ theme }) => theme.colors.footerText};

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;

    & > * {
      width: 100%;
      text-align: center;
    }
  }
`;

export const IconContainerStyled = styled.div`
  display: flex;
  align-items: center;
  padding: 1.5rem 2rem;

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
  }
`;

export const PhoneIconStyled = styled(BsFillTelephoneFill)`
 
  margin-right: 8px;
  font-size:30px;
  
  
`;

export const MailIconStyled = styled(AiOutlineMail)`
   margin-right: 8px;
  font-size:30px;

`;

export const FacebookIconStyled = styled(FaFacebook)`
   margin-right: 8px;
  font-size:30px;

`;

export const PositionIconStyled = styled(GiPositionMarker)`
   margin-right: 8px;
  font-size:30px;
 
`;


    

import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import HomePage from '../src/components/pages/HomePage';
import MentionsLegales from '../src/components/pages/MentionsLegalesPage';
import React, { useState } from "react";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./components/styles/Globals.styled";
import PolitiqueDeConfidentialite from "./components/pages/PolitiqueDeConfidentialitePage";
import './assets/fonts/fonts.css';

function App() {

  //const [darkMode, setDarkMode] = useState(false);
  const [darkMode, setDarkMode] = useState(JSON.parse(localStorage.getItem("darkMode")) || false);

  function handleSetDarkMode(value) {
    localStorage.setItem("darkMode", value);
    setDarkMode(value);
  }
  const theme = {
    colors: {
      textColorHtml: darkMode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
      htmlBackground: darkMode ? "rgba(13, 27, 42,1)" : "rgba(255, 164, 76,1)",
      // Link
      aLink: darkMode ? "rgba(255, 255, 255, 1)" : "",
      aLinkHover: darkMode ? "rgba(250, 31, 120,1)" : "rgba(255, 164, 76,1)",
      // CTA Buttons
      backgroundAndBorderButton: darkMode
        ? "rgba(140, 25, 72, 1)"
        : "rgba(180, 12, 38,1)",
      textButtonOne: darkMode
        ? "rgba(240, 247, 255, 1)"
        : "rgba(255, 255, 255, 1)",
      textButtonTwo: darkMode ? "rgba(51, 197, 129, 1)" : "rgba(51, 51, 51, 1)",
      aHoverBackgroundAndBorderButton: darkMode
        ? "rgba(255, 50, 100, 1)"
        : "rgba(144, 12, 28,1)",
      // HR
      lineHrOne: darkMode ? "" : "",
      lineHrTwo: darkMode ? "" : "",
      // HR Divider
      hrIconColor: darkMode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
      hrDividerColor: darkMode ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 1)",
      // Scrollbar
      webkitScrollbarTrack: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,0.5)",
      webkitScrollbarThumb: darkMode
        ? "rgba(255,255,255,0.5)"
        : "rgba(144, 12, 28,0.8)",
      webkitScrollbarThumbHover: darkMode
        ? "rgba(255,255,255,0.8)"
        : "rgba(255,255,255,0.8)",
      // NavBar
      navBarColor: darkMode ? "rgba(10, 21, 33,1)" : "rgba(144, 12, 28,1)",
      backgroundMobileButton: darkMode ? "" : "",
      backgroundMobileMenu: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,1)",
      textNavbar: darkMode ? "" : "rgba(255,255,255,1)",
      // Lines on Mobile Menu
      lineMobile: darkMode ? "rgba(255,255,255,1)" : "rgba(255,255,255,1)",
      // Waves

      // Hero-Section
      heroSectionHeaderLinearOne: darkMode ? "" : "rgba(144, 12, 28,0.3)",
      heroSectionHeaderLinearTwo: darkMode ? "" : "rgba(255, 164, 76,0.9)",
      titleAnimAlice: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",
      titleTwoAnimAlice: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,40,1)",
      titleThreeLinearGradientOne: darkMode
        ? "rgba(255,255,255,1)"
        : "rgba(50,0,0,0.5)",
      titleThreeLinearGradientTwo: darkMode
        ? "rgba(255,255,50,1)"
        : "rgba(50,50,200,1)",
      arrowDown: darkMode ? "" : "rgba(180, 12, 38,1)",
      arrowDownHover: darkMode ? "" : "rgba(144, 12, 28,1)",
      // About Section
      aboutSectionBackground: darkMode ? "rgba(20, 41, 55,1)" : "#ffe599",
      aboutSectionContainerTextBackground: darkMode ? "" : "",
      aboutSectionTextContent: darkMode
        ? "rgba(255,255,255,1)"
        : "rgba(0,0,0,1)",
      aboutSectionTextTitle: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",

      // Services Section
      servicesSectionBackground: darkMode
        ? "rgba(16, 25, 44,1)"
        : "rgba(255,255,255,1)",
      servicesTextTitle: darkMode
        ? "rgba(255, 164, 76,1)"
        : "rgba(144, 12, 28,1)",
      servicesTextParagraph: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",

      // Tarifs Section
      tarifsSectionBackground: darkMode
        ? "rgba(20, 41, 55,1)"
        : "rgba(255, 164, 76,1)",
      tarifsSectionBackgroundTableHeader: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,1)",
      tarifsSectionBackgroundTableHeaderText: darkMode
        ? "rgba(255,255,255,1)"
        : "rgba(255,255,255,1)",
      tarifsSectionBackgroundRowOne: darkMode ? "purple" : "white",
      tarifsSectionBackgroundRowTwo: darkMode
        ? "rgba(120, 141, 155,1)"
        : "rgba(230, 211, 211, 1)",

      tarifsSectionAccordionContainer: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,1)",
      tarifsSectionAccordionContent: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,0.7)",
      tarifsSectionAccordionText: darkMode
        ? "rgba(255,255,255,1)"
        : "rgba(255,255,255,1)",
      // Tarifs Simulator
      tarifsSimulatorTitle: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,1)",
      tarifsSimulatorPrice: darkMode ? "rgba(255,255,255,1)" : "white",
      tarifsSimulatorTotal: darkMode ? "purple" : "(10,50,50,1)",
      tarifsSimulatorTextTable: darkMode ? "rgba(0,0,0,1)" : "rgba(0,0,0,1)",
      tarifsSimulatorTextTotalTable: darkMode
        ? "rgba(255,255,255,1)"
        : "rgba(255,255,255,1)",
      tarifsSimulatorLabel: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",

      // Galerie Section
      galerieSectionBackground: darkMode
        ? "rgba(16, 25, 44,1)"
        : "rgba(255, 255, 255,1)",
      // Contact Section
      contactSectionBackground: darkMode
        ? "rgba(20, 41, 55,1)"
        : "rgba(255, 164, 76,1)",
      // Footer Section
      footerSectionBackground: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(144, 12, 28,1)",
      footerText: darkMode ? "rgba(255,255,255,1)" : "rgba(255,255,255,1)",
      // Footer copyright Section
      footerCopyrightSectionBackground: darkMode ? "black" : "black",
      // Moon & Sun theme
      fillSvgTheme: darkMode ? "rgba(13, 27, 42,1)" : "rgba(255, 164, 76,1)",
      //  swapHoverSvgTheme : darkMode ? "rgba(250, 31, 120,1)" : "rgba(0, 0, 76,1)",
      // Testimonials
      backgroundColorTestimonials: darkMode
        ? "rgba(10, 21, 33,1)"
        : "rgba(255, 80, 60,1)",
      textColorTestimonials: darkMode ? "rgba(255,255,255,1)" : "rgba(0,0,0,1)",
      testimonialsStar: darkMode ? "yellow" : "yellow",

      // Modal envoi formulaire
      modalSendForm : darkMode ? "rgba(0,0,0,0.8)" : "rgba(255,255,255,0.8)",

      // Mentions légales
     mentionsLegalesh1 : darkMode ? "(200,150,250,1)" : "red",
     mentionsLegalesh2 : darkMode ? "purple" : "purple",
     mentionsLegalesh3 : darkMode ? "rgba(255,255,0,0.8)" : "rgba(100, 164, 76,1)",
     mentionsLegalesParagraphe : darkMode ? "rgba(255,255,255,0.8)" : "black",
     mentionsLegalesSectionBackground : darkMode ? "rgba(13, 27, 42,1)" : "rgba(255, 255, 255,1)",
     mentionsLegalesSectionHover : darkMode ? "rgba(130, 27, 42,1)" : "rgba(200, 20, 76,1)",
     
     // Politique de confidentialité
     politiqueDeConfidentialiteSectionBackground : darkMode ? "rgba(13, 27, 42,1)" : "rgba(255, 255, 255,1)",
    },
    mobile: "1150px",
    transition: "all 400ms ease-in-out",
  };


  return (
    <>
     <ThemeProvider theme={theme}>
      <GlobalStyles />
    <Router>
      <Routes>
        <Route path='/' element={<HomePage darkMode={darkMode}
        setDarkMode={handleSetDarkMode}
        theme={theme}/>} />
        <Route exact path='/mentions-legales' element={<MentionsLegales darkMode={darkMode}
        setDarkMode={handleSetDarkMode}
        theme={theme}/>} />
        <Route exact path='/politique-de-confidentialite' element={<PolitiqueDeConfidentialite darkMode={darkMode}
        setDarkMode={handleSetDarkMode}
        theme={theme}/>} />
      </Routes>
    </Router>
    </ThemeProvider>
    </>
  );
}

export default App;